import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,

  serverUrl: 'https://api.joinohana.xyz',

  loginUrl: 'https://login.joinohana.xyz',

  visitorGlobalUrl: 'https://visitor.joinohana.xyz',

  logging: {
    enabled: false,
  },
};
